import { CONSTANTS } from "../constants";
import { useIsMobile } from "./useIsMobile";

export const useMultiGenderTypeSupport = (inputRef) => {
    const isMobile = useIsMobile();

    function setCaretPosition(position) {
        if (inputRef === null) {
            return;
        }
        const textArea = inputRef.current;
        textArea.focus();
        textArea.setSelectionRange(position, position);
    }

    return {
        preventCharacterInsertion: (e) => {
            if (
                isMobile ||
                !e.shiftKey ||
                e.key === "Shift" ||
                !CONSTANTS.MULTI_GENDER_LETTERS_MAPPING[e.nativeEvent.code]
            ) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();
        },

        handleKeyDown: (e) => {
            if (
                isMobile ||
                !e.shiftKey ||
                e.key === "Shift" ||
                inputRef === null ||
                !CONSTANTS.MULTI_GENDER_LETTERS_MAPPING[e.nativeEvent.code]
            ) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            const textArea = inputRef.current;
            const cursorPosition = textArea.selectionStart;
            textArea.value = [
                textArea.value.slice(0, cursorPosition),
                CONSTANTS.MULTI_GENDER_LETTERS_MAPPING[e.nativeEvent.code],
                textArea.value.slice(cursorPosition),
            ].join("");
            setCaretPosition(cursorPosition + 1);
        },
    };
};
