import { Logo } from "./Logo";
import { Link } from "react-router-dom";
import { DownloadLink } from "./DownloadLink";
import { ReactComponent as ContactIcon } from "../Icons/contact.svg";
import { useIsMobile } from "../Hooks/useIsMobile";
import ReactTooltip from "react-tooltip";
import "./Header.scss";

export const MinimalHeader = () => {
    const isMobile = useIsMobile();

    return (
        <header className={`header flex ${isMobile ? `header--mobile` : ``}`}>
            <Logo />
            {!isMobile && (
                <div className="menu-left flex">
                    <div className="separator--left flex center-self">
                        <Link to="/about" className="flex link">
                            <span className="contact-icon flex center-self">
                                <ContactIcon className="width-100p" />
                            </span>
                            אודות ושאלות נפוצות
                        </Link>
                    </div>
                    <DownloadLink className="mr--48" />
                </div>
            )}
            <ReactTooltip
                place="bottom"
                arrowColor="transparent"
                delayShow={300}
            />
        </header>
    );
};
