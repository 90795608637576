import { Link } from "react-router-dom";

export const Logo = () => {
    return (
        <div className="menu-right flex">
            <div className="header-logo">
                <Link to="/">
                    <picture>
                        <source
                            media="(min-width: 1200px) and (max-width: 1600px)"
                            srcSet="/images/header-logo-1280-1600.svg"
                        />
                        <source
                            media="(max-width: 1200px)"
                            srcSet="/images/header-logo--mobile.svg"
                        />
                        <img src={`/images/header-logo.svg`} alt="Logo" />
                    </picture>
                </Link>
            </div>
        </div>
    );
};
