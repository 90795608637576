import React from "react";
import { ReactComponent as LeftArrow } from "../Icons/left-arrow.svg";
import { FAQ as FAQData } from "../Data/FAQ";
import { sendAnalyticsEvent } from "../Helpers/Analytics";
import { useIsMobile } from "../Hooks/useIsMobile";
import { MobileFAQ } from "./Mobile/FAQ";

export const FAQ = () => {
    const isMobile = useIsMobile();
    const QuestionsList = () => {
        return FAQData.map((faqItem) => ({
            question: faqItem.question,
            id: faqItem.id,
        })).map((questionItem) => (
            <div key={questionItem.id} className="flex">
                <div>
                    <LeftArrow className="faq-item-arrow" />
                </div>
                <div className="questions-list-question-container">
                    <a
                        href={`#${questionItem.id}`}
                        className="questions-list-question__a"
                        onClick={() => {
                            sendAnalyticsEvent(
                                `navigate-about-${questionItem.id}`
                            );
                        }}
                    >
                        {questionItem.question}
                    </a>
                </div>
            </div>
        ));
    };

    const AnswersList = () => {
        return FAQData.map((item) => {
            const questionText = item.question2
                ? item.question2
                : item.question;
            return (
                <div className="faq-answers" id={item.id} key={item.id}>
                    <div className="faq-answers__question">{questionText}</div>
                    <div
                        className="faq-answers__answer"
                        dangerouslySetInnerHTML={{
                            __html: item.answer.replace(/\n/g, "<br />"),
                        }}
                    />
                </div>
            );
        });
    };

    return (
        <div className="faq-section-contents">
            {!isMobile && (
                <>
                    <div className="faq__questions-list">
                        <QuestionsList />
                    </div>
                    <div className="faq__answers">
                        <AnswersList />
                    </div>
                </>
            )}
            {isMobile && <MobileFAQ faqData={FAQData} />}
        </div>
    );
};
