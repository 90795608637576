import React, { useState, useRef } from "react";
import { sendFeedbackEmail } from "../Helpers/SendMail";
import { ReactComponent as FacebookIcon } from "../Icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../Icons/instagram.svg";
import { sendAnalyticsEvent } from "../Helpers/Analytics";
import { useIsMobile } from "../Hooks/useIsMobile";
import { useMultiGenderTypeSupport } from "../Hooks/useMultiGenderTypeSupport";

export const ContactForm = (props) => {
    const [technicalFeedback, setTechnicalFeedback] = useState("");
    const [generalFeedback, setGeneralFeedback] = useState("");
    const feedbackSentDivRef = useRef(null);
    const feedbackFormElement = useRef(null);
    const isMobile = useIsMobile();
    const nameInputRef = useRef(null);
    const generalFeedbackRef = useRef(null);
    const technicalFeedbackRef = useRef(null);
    const nameMultiGenderSupport = useMultiGenderTypeSupport(nameInputRef);
    const generalFeedbackMultiGenderSupport =
        useMultiGenderTypeSupport(generalFeedbackRef);
    const technicalFeedbackMultiGenderSupport =
        useMultiGenderTypeSupport(technicalFeedbackRef);

    const [isTextareaRequired, setIsTextareaRequired] = useState(true);
    const [feedbackSentSuccessfully, setFeedbackSentSuccessfully] =
        useState(false);
    const [feedbackSendButtonDisabled, setFeedbackSendButtonDisabled] =
        useState(false);

    function resetForm() {
        requestAnimationFrame(() => {
            feedbackSentDivRef.current.classList.add("animation--fade-out");
            setTimeout(() => {
                setFeedbackSendButtonDisabled(false);
                setFeedbackSentSuccessfully(false);
            }, 800);
        });
    }

    function checkRequiredFields() {
        setIsTextareaRequired(
            generalFeedback.trim() === "" && technicalFeedback.trim() === ""
        );
    }

    function handleGeneralFeedbackFieldChange(e) {
        setGeneralFeedback(e.currentTarget.value);
        checkRequiredFields();
    }

    function handleTechnicalFeedbackFieldChange(e) {
        setTechnicalFeedback(e.currentTarget.value);
        checkRequiredFields();
    }

    function handleFeedbackSubmit(e) {
        e.preventDefault();
        setFeedbackSendButtonDisabled(true);
        sendFeedbackEmail(feedbackFormElement.current).then((res) => {
            setFeedbackSentSuccessfully(res.data === "success");
            setFeedbackSendButtonDisabled(res.data === "success");
            sendAnalyticsEvent("contact-form-sent");
            setTimeout(resetForm, 5000);
        });
    }

    return (
        <div className="mt-40px">
            <form onSubmit={handleFeedbackSubmit} ref={feedbackFormElement}>
                <p className="lh-39">
                    יש לכ׋ שאלות? תהיות? רעיונות? נתקלת׋ בקשיים טכניים? רוצ׊׉
                    סתם לדבר?
                    <span className="space--desktop-only">
                        {isMobile ? " " : ""}
                    </span>
                    השאירו הודעה כאן!
                </p>
                <div className="contact-us-form">
                    <div className="flex contact-us-form__name-and-email">
                        <label className="block width-49p">
                            <div className="muted contact-us-form__label">
                                שם
                            </div>
                            <input
                                required
                                type="text"
                                name="name"
                                className="width-100p contact-us-form-input font-alef-multi-gndr"
                                ref={nameInputRef}
                                onKeyPress={
                                    nameMultiGenderSupport.preventCharacterInsertion
                                }
                                onKeyDown={nameMultiGenderSupport.handleKeyDown}
                                onKeyUp={
                                    nameMultiGenderSupport.preventCharacterInsertion
                                }
                            />
                        </label>
                        <label className="block width-49p">
                            <div className="muted contact-us-form__label">
                                מייל
                            </div>
                            <input
                                required
                                type="text"
                                name="email"
                                className="width-100p contact-us-form-input font-alef-multi-gndr"
                            />
                        </label>
                    </div>
                    {!feedbackSentSuccessfully && (
                        <div>
                            <label className="block mt-60px">
                                <div className="muted contact-us-form__label">
                                    לפניות בנושאים שונים, שאלות, משוב, הזמנת
                                    הרצאות, שירותי ייעוץ
                                </div>
                                <textarea
                                    required={isTextareaRequired}
                                    name="generalFeedback"
                                    className="contact-us-form-input contact-us-form-input--textarea font-alef-multi-gndr"
                                    onBlur={handleGeneralFeedbackFieldChange}
                                    onChange={handleGeneralFeedbackFieldChange}
                                    ref={generalFeedbackRef}
                                    onKeyPress={
                                        generalFeedbackMultiGenderSupport.preventCharacterInsertion
                                    }
                                    onKeyDown={
                                        generalFeedbackMultiGenderSupport.handleKeyDown
                                    }
                                    onKeyUp={
                                        generalFeedbackMultiGenderSupport.preventCharacterInsertion
                                    }
                                />
                            </label>
                            <label className="block mt-60px">
                                <div className="muted contact-us-form__label">
                                    לבקשה לסיוע טכני או דיווח על תקלה
                                </div>
                                <textarea
                                    required={isTextareaRequired}
                                    name="technicalFeedback"
                                    className="contact-us-form-input contact-us-form-input--textarea font-alef-multi-gndr"
                                    onBlur={handleTechnicalFeedbackFieldChange}
                                    onChange={
                                        handleTechnicalFeedbackFieldChange
                                    }
                                    ref={technicalFeedbackRef}
                                    onKeyPress={
                                        technicalFeedbackMultiGenderSupport.preventCharacterInsertion
                                    }
                                    onKeyDown={
                                        technicalFeedbackMultiGenderSupport.handleKeyDown
                                    }
                                    onKeyUp={
                                        technicalFeedbackMultiGenderSupport.preventCharacterInsertion
                                    }
                                />
                            </label>
                        </div>
                    )}
                    {feedbackSentSuccessfully && (
                        <div
                            className="feedback-sent-box"
                            ref={feedbackSentDivRef}
                        >
                            <div className="feedback-sent-box__text">
                                תודה שכתבת לעברית רב־מגדרית, פנייתך התקבלה!
                                <br />
                                יינתן מענה בהקדם לכתובת המייל :)
                            </div>
                        </div>
                    )}
                    <div className={`mt-80px flex contact-us-form__footer`}>
                        <button
                            disabled={feedbackSendButtonDisabled}
                            type="submit"
                            className="contact-us-send-button button font-alef-multi-gndr zero-padding no-outline"
                        >
                            שליחה
                        </button>
                        <div className="social-links flex align-items-center">
                            {isMobile && (
                                <span className="muted visit-us-text">
                                    מוזמנ׊׉ לקפוץ לביקור:
                                </span>
                            )}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/MultiGenderHebrew"
                                className="facebook-icon inline-block"
                                onClick={() => {
                                    sendAnalyticsEvent("visit-facebook-page");
                                }}
                                data-tip="עברית רב־מגדרית בפייסבוק"
                            >
                                <FacebookIcon className="facebook-icon__svg" />
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/multigenderhebrew"
                                className="instagram-icon inline-block"
                                onClick={() => {
                                    sendAnalyticsEvent("visit-instagram-page");
                                }}
                                data-tip="עברית רב־מגדרית באינסטגרם"
                            >
                                <InstagramIcon className="instagram-icon__svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
