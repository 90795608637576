import { MinimalHeader } from "../Header/MinimalHeader";

import { ReactComponent as UpArrow } from "../Icons/up-arrow.svg";
import { useEffect } from "react";

import "./About.scss";
import { getSectionNavigationLinks, removeUrlHash } from "../Helpers/Helpers";
import { CONSTANTS } from "../constants";
import { FAQ } from "./FAQ";
import { useIsMobile } from "../Hooks/useIsMobile";
import { sendAnalyticsEvent } from "../Helpers/Analytics";
import { ContactForm } from "../Components/ContactForm";

export const About = () => {
    const isMobile = useIsMobile();

    const sectionNavigation = (links) => {
        return (
            <div className="section-header">
                {links.map((link, index) => {
                    return (
                        <a
                            href={`${link.href}`}
                            key={link.href}
                            className="section-header__a"
                            onClick={() =>
                                sendAnalyticsEvent(
                                    `navigate-about${link.href}`.replace(
                                        "#",
                                        "-"
                                    )
                                )
                            }
                        >
                            <span
                                className={`${
                                    link.isActive
                                        ? "section-header__active"
                                        : "section-header__link"
                                } ${index > 0 ? "mr--46" : ""}`}
                            >
                                {link.label}
                            </span>
                        </a>
                    );
                })}
            </div>
        );
    };

    const ScrollToTop = () => {
        return (
            <button
                className={`button--transparent zero-padding button no-outline scroll-to-top-button ${
                    isMobile
                        ? "scroll-to-top-button--mobile"
                        : "scroll-to-top-button--desktop"
                }`}
                onClick={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    removeUrlHash();
                }}
                data-tip="חזרה לראש הדף"
            >
                <UpArrow
                    style={{
                        width: "25px",
                        height: "25px",
                    }}
                />
            </button>
        );
    };

    useEffect(() => {
        if (window.location.hash !== "") {
            return;
        }

        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <MinimalHeader />
            <main
                className={`about__main ${
                    isMobile ? `about__main--mobile mt-1em` : ``
                }`}
            >
                <section id="about">
                    {sectionNavigation(getSectionNavigationLinks("about"))}
                    <span
                        className="about__span"
                        dangerouslySetInnerHTML={{
                            __html: isMobile
                                ? CONSTANTS.DEFAULTS.ABOUT_TEXT_MOBILE
                                : CONSTANTS.DEFAULTS.ABOUT_TEXT,
                        }}
                    />
                </section>
                {isMobile && <ScrollToTop />}
                <section id="contact" className="mt-124px contact-us-section">
                    {sectionNavigation(getSectionNavigationLinks("contact"))}
                    <ContactForm />
                </section>
                {isMobile && <ScrollToTop />}
                <section id="faq" className="mt-124px">
                    {sectionNavigation(getSectionNavigationLinks("faq"))}
                    <p>
                        הנה רשימה של כמה שאלות נפוצות והתשובות להן. אם יש לכ׋
                        שאלות נוספות או תהיות שלא קיבלו מענה, כתבו הודעה בטופס{" "}
                        <a
                            className="internal-link"
                            href="#contact"
                            onClick={() =>
                                sendAnalyticsEvent("navigate-about-contact")
                            }
                        >
                            יצירת הקשר
                        </a>
                        .
                    </p>
                    <FAQ />
                </section>
                <ScrollToTop />
            </main>
        </>
    );
};
