export const isMobile = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

export const hexToRgb = (hex) => {
    const value = hex.split("#")[1];
    const r = value.substring(0, 2),
        g = value.substring(2, 4),
        b = value.substring(4, 6);

    return [r, g, b].map((v) => parseInt(v, 16));
};

export const rgbToHex = (rgb) => {
    return rgb.reduce((acc, curr) => {
        let hex = curr.toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return acc + hex;
    }, "#");
};

export const getSectionNavigationLinks = (currentSection) => {
    switch (currentSection) {
        case "about":
            return [
                {
                    href: "#about",
                    label: "אודות",
                    isActive: true,
                },
                {
                    href: "#contact",
                    label: "יצירת קשר",
                    isActive: false,
                },
                {
                    href: "#faq",
                    label: "שאלות נפוצות",
                    isActive: false,
                },
            ];
        case "contact":
            return [
                {
                    href: "#contact",
                    label: "יצירת קשר",
                    isActive: true,
                },
                {
                    href: "#faq",
                    label: "שאלות נפוצות",
                    isActive: false,
                },
                {
                    href: "#about",
                    label: "אודות",
                    isActive: false,
                },
            ];
        case "faq":
            return [
                {
                    href: "#faq",
                    label: "שאלות נפוצות",
                    isActive: true,
                },
                {
                    href: "#about",
                    label: "אודות",
                    isActive: false,
                },
                {
                    href: "#contact",
                    label: "יצירת קשר",
                    isActive: false,
                },
            ];
        default:
            return [];
    }
};

export const removeUrlHash = () => {
    window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
    );
};
