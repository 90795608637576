const isSafariOrFirefox =
    navigator.userAgent.indexOf("Firefox/") >= 0 ||
    (navigator.userAgent.indexOf("Safari/") >= 0 &&
        navigator.userAgent.indexOf("Chrome/") === -1 &&
        navigator.userAgent.indexOf("Edg/") === -1);

export const CONSTANTS = {
    SLIDER: {
        MINIMUM_SIZE: 16,
        MAXIMUM_SIZE: 200,
        MAXIMUM_SIZE_MOBILE: 60,
    },
    COLORS: {
        WHITE: "#fcfcfc",
        BLACK: "#030303",
    },
    BODY_INVERSE_CLASS: "body--inverse",
    DEFAULTS: {
        MOBILE_TEXT_SIZE: 26,
        TEXT_SIZE: 44,
        TEXT_SIZE_MEDIUM_SCREEN: 38,
        CONTRAST: false,
        TEXTAREA_TEXT: `ברוכ׊׉ הבא׊׉! עברית רב־מגדרית היא מערכת של אותיות עבריות חדשות, המאפשרות קריאה וכתיבה רב־מגדריות. ההקלדה חופשית\u00A0– גם את׌ יכול׌ להתנסות בעצמך ממש כאן! הקליקו על סימן השאלה בתפריט העליון, לחצו על כל אחת מהאותיות בחלונית כדי לגלות מהו קיצור המקלדת שלה ולדוגמאות, ואחר כך לחצו על טקסט זה ונסו שיפט + אות.

כדי להשתמש בעברית רב־מגדרית במחשב שלכ׋, הורידו את קבצי ההתקנה בלחיצה על כפתור ההורדה בתפריט העליון והשתמשו במדריך המצורף להם.

עברית רב־מגדרית מנכיחה את הנשים בשפה העברית ומייצרת מרחב חדש בשפה ל׭נשים שזהות׋ המגדרית היא א־בינארית. היא כוללת 12 תווים חדשים, ביניהם סימן ניקוד רב־מגדרי: ׭, ׬, ׌, ׎, ׫, ׊, ׏, ׋, ׉, ׮, ׍, ׈.`,
        TEXTAREA_MOBILE_TEXT: `<p>ברוכ׊׉ הבא׊׉! עברית רב־מגדרית היא מערכת של אותיות עבריות חדשות, המאפשרות קריאה וכתיבה רב־מגדריות.</p>

<p>עברית רב־מגדרית כוללת 12 תווים חדשים, שאחד מהם הוא סימן ניקוד רב־מגדרי: ׭, ׬, ׌, ׎, ׫, ׊, ׏, ׋, ׉, ׮, ׍, ׈.</p>

<p>עברית רב־מגדרית מנכיחה את הנשים בשפה העברית ומייצרת מרחב ל׭נשים שזהות׋ המגדרית היא א־בינארית.</p>

<p>לצערנו, בשלב זה אין עדיין תמיכה בהקלדה במובייל. כדי להתנסות, הכנסו מהמחשב. תודה :)</p>`,
        ABOUT_TEXT: `<p class="lh-39">עברית רב־מגדרית (Multi-Gender Hebrew) היא מערכת של אותיות עבריות חדשות, המאפשרות קריאה וכתיבה רב־מגדריות, לה שתי מטרות מרכזיות: הנכחת הנשים בשפה העברית ויצירת מרחב חדש בשפה לזהויות מגדריות א־בינאריות.</p>
        <p class="lh-39">תוכלו להוריד את אותיות עברית רב־מגדרית ולהשתמש בהן לכתיבת טקסטים בעברית. על מנת לעשות זאת, לחצו על כפתור ההורדה בתפריט העליון, ותורידו למחשבכ׋ קבצי התקנת פונט ומקלדת תומכת. הגופן אותו תורידו נקרא Alef MultiGndr, והוא כולל את אותיות עברית רב־מגדרית. זהו גופן ברישיון פתוח לשימוש חופשי ובחינם, שמתבסס על הגופן הפתוח "אלף", ברישיון דומה. בשלב זה, למרבה הצער, לא ניתן להתקין את האותיות החדשות במובייל, אך תמיכה עתידית מתוכננת.</p>
        <p class="lh-39">כדי ללמוד כיצד להשתמש באותיות החדשות, התנסו תחילה ב<a class="link" href="/">ממשק ההתנסות</a> (ללא צורך בהורדה כלשהי). הנחיות התקנה ושימוש מפורטות ירדו למחשבכ׋ יחד עם קבצי ההתקנה בלחיצה על כפתור ההורדה. כדי להתחיל להקליד בעברית רב־מגדרית במחשב, מומלץ לקרוא את ההוראות במלואן. ובכל זאת, אל תוותרו על ההתנסות בממשק, היא תלמד אתכ׋ את כל מה שצריך לדעת על השימוש באותיות החדשות.</p>
        <p class="lh-39">עברית רב־מגדרית נוצרה מתוך ההבנה שהשפה העברית היא שפה פטריארכלית, שבה במקרים רבים הנשים מודרות מהשיח – ורבות מדווחות שהן חשות שאינן נוכחות בו. בנוסף, בעברית ישנה חלוקה דיכוטומית בין גבר או אישה – ואין מקום לרצף המגדרים שביניהם. אם לפשט את הדברים, לעיתים ישנו דיסוננס בין השפה ומי שהיא מייצגת לבין מי שהיא אמורה לייצג. עברית רב־מגדרית היא הצעה אחת לפתרון הדיסוננס הזה, מעין "עדכון" של השפה כך שהיא תבטא אפשרות חדשה לייצוג כולן, כולם וכול׋ בקריאה וכתיבה.</p>
        <p class="lh-39">בתהליך העבודה על עברית רב־מגדרית נשאלו שאלות כמו: איפה השפה העברית מגבילה ואיפה היא דווקא מאפשרת? למה חשוב לייצר מרחב רב־מגדרי בשפה העברית? מי מוצא׍ עצמ׎ מודר׍ בעברית של ימינו? העבודה הייתה טיפוגרפית במהותה, באמצעות חיפוש ומחקר של צורניות האותיות, תוך מתיחה של גבולות העברית וגבולות הקריאות לסירוגין. מקורות ההשראה היו מגוונים: החל מהתבוננות על גלגולה ההסטורי של העברית ולשונות עליהן היא מתבססת, עבור בהסתכלות על גופנים עבריים ישנים וחדשים, מסורתיים ומודרניים, ואופן פיתוחם, וכלה בתרגילים וניסויים טיפוגרפיים לרענון המחשבה. שאלת המחקר הייתה, כיצד ניתן ליצור סימן חדש בעברית – כזה שיהיה בעל משמעות ברורה – ושיהיה חלק אינטגרלי מהאותיות העבריות?</a>
        <p class="lh-39">בעזרת האותיות החדשות של עברית רב־מגדרית מורכבים מילים ומשפטים בעלי משמעות חדשה, ובה הזדמנות לשוויון מגדרי: בין נשים, גברים, וא־בינאר׊׉.</p>
        <p>-</p>
        <p class="lh-39">מיכל שומר, יוצרת ומעצבת עברית רב־מגדרית, בעלת תואר ראשון בעיצוב תקשורת חזותית מהמכון הטכנולוגי בחולון, HIT. <a class="link" href="https://il.linkedin.com/in/kohendidi" target="_blank">דידי כהן</a>, פיתחה את מקלדות עברית רב־מגדרית. <a target="_blank" href="https://www.linkedin.com/in/mulidayan/" class="link">מולי דיין</a>, אמון על כתיבת הקוד של אתר זה. עברית רב־מגדרית יצאה לעולם בעזרת ׭נשים יקר׊׉ נוספ׊׉: תודה גדולה לכל מי שתמכו, יעצו, תרמו וסייעו לעברית רב־מגדרית.</p>
        <p class="lh-39">זכויות יוצר׊׉: יוצרת עברית רב־מגדרית קוראת למעצב׊׍ אותיות לעצב את אותיות עברית רב־מגדרית לגופנים שלה׋, בשאיפה שעברית רב־מגדרית תהיה זמינה ונגישה בכל גופן עברי. עם זאת, שלדי האותיות החדשות וצורניות האותיות מוגנים על פי חוק זכויות יוצר׊׉. כדי לעצב ולהוסיף את אותיות עברית רב־מגדרית לגופנים נוספים, יש לקבל אישור מראש ובכתב מהיוצרת. יתכן והדבר יהיה כרוך בתשלום, ובכל מקרה אין לעשות זאת ללא אישור כזה. ליישום עברית רב־מגדרית בגופנים שלכ׋, אנא צרו קשר בטופס שנמצא <a class="internal-link" href="#contact">כאן</a>. מידע מלא על זכויות השימוש נמצא בקובץ הרישיון, שיורד יחד עם קבצי ההתקנה.</p>
        <p class="lh-39">הגופן "אלף" נוצר ועוצב על ידי הגילדה: מיכל סהר ודני "הטייס" מירב; מושון זר אביב, ניר ייני. הבהרה: עברית רב־מגדרית פותחה באופן עצמאי ונפרד מ-"אלף". הזכויות והקרדיט על פיתוח "אלף" שייכות למי שיצרו אותו.</p>`,
        ABOUT_TEXT_MOBILE: `<p>עברית רב־מגדרית (Multi-Gender Hebrew) היא מערכת של אותיות עבריות חדשות, המאפשרות קריאה וכתיבה רב־מגדריות, לה שתי מטרות מרכזיות: הנכחת הנשים בשפה העברית ויצירת מרחב חדש בשפה לזהויות מגדריות א־בינאריות. </p>
        <p>תוכלו להוריד את אותיות עברית רב־מגדרית ולהשתמש בהן לכתיבת טקסטים בעברית. על מנת לעשות זאת, יש להכנס לאתר זה מהמחשב ולהוריד קבצי התקנת פונט ומקלדת תומכת. הגופן אותו תורידו למחשב נקרא Alef MultiGndr, והוא כולל את אותיות עברית רב־מגדרית. זהו גופן ברישיון פתוח לשימוש חופשי ובחינם, שמתבסס על הגופן הפתוח "אלף", ברישיון דומה. בשלב זה, למרבה הצער, לא ניתן להתקין את האותיות החדשות במובייל, אך תמיכה עתידית מתוכננת.</p>
        <p>כדי ללמוד כיצד להשתמש באותיות החדשות, מומלץ להתנסות תחילה בממשק ההתנסות בכניסה מהמחשב (ללא צורך בהורדה כלשהי). הנחיות התקנה ושימוש מפורטות ירדו למחשבכ׋ יחד עם קבצי ההתקנה בלחיצה על כפתור ההורדה. כדי להתחיל להקליד בעברית רב־מגדרית במחשב, מומלץ לקרוא את ההוראות במלואן. ובכל זאת, אל תוותרו על ההתנסות בממשק, היא תלמד אתכ׋ את כל מה שצריך לדעת על השימוש באותיות החדשות.</p>
        <p>עברית רב־מגדרית נוצרה מתוך ההבנה שהשפה העברית היא שפה פטריארכלית, שבה במקרים רבים הנשים מודרות מהשיח – ורבות מדווחות שהן חשות שאינן נוכחות בו. בנוסף, בעברית ישנה חלוקה דיכוטומית בין גבר או אישה – ואין מקום לרצף המגדרים שביניהם. אם לפשט את הדברים, לעיתים ישנו דיסוננס בין השפה ומי שהיא מייצגת לבין מי שהיא אמורה לייצג. עברית רב־מגדרית היא הצעה אחת לפתרון הדיסוננס הזה, מעין "עדכון" של השפה כך שהיא תבטא אפשרות חדשה לייצוג כולן, כולם וכול׋ בקריאה וכתיבה. </p>
        <p>בתהליך העבודה על עברית רב־מגדרית נשאלו שאלות כמו: איפה השפה העברית מגבילה ואיפה היא דווקא מאפשרת? למה חשוב לייצר מרחב רב־מגדרי בשפה העברית? מי מוצא׍ עצמ׎ מודר׍ בעברית של ימינו? העבודה הייתה טיפוגרפית במהותה, באמצעות חיפוש ומחקר של צורניות האותיות, תוך מתיחה של גבולות העברית וגבולות הקריאות לסירוגין. מקורות ההשראה היו מגוונים: החל מהתבוננות על גלגולה ההסטורי של העברית ולשונות עליהן היא מתבססת, עבור בהסתכלות על גופנים עבריים ישנים וחדשים, מסורתיים ומודרניים, ואופן פיתוחם, וכלה בתרגילים וניסויים טיפוגרפיים לרענון המחשבה. שאלת המחקר הייתה, כיצד ניתן ליצור סימן חדש בעברית – כזה שיהיה בעל משמעות ברורה – ושיהיה חלק אינטגרלי מהאותיות העבריות?</p>
        <p>בעזרת האותיות החדשות של עברית רב־מגדרית מורכבים מילים ומשפטים בעלי משמעות חדשה, ובה הזדמנות לשוויון מגדרי: בין נשים, גברים, וא־בינאר׊׉.</p>
        <p>-</p>
        <p>מיכל שומר, יוצרת ומעצבת עברית רב־מגדרית, בעלת תואר ראשון בעיצוב תקשורת חזותית מהמכון הטכנולוגי בחולון, HIT. <a class="link" href="https://il.linkedin.com/in/kohendidi" target="_blank">דידי כהן</a>, פיתחה את מקלדות עברית רב־מגדרית. <a target="_blank" href="https://www.linkedin.com/in/mulidayan/" class="link">מולי דיין</a>, אמון על כתיבת הקוד של אתר זה. עברית רב־מגדרית יצאה לעולם בעזרת ׭נשים יקר׊׉ נוספ׊׉: תודה גדולה לכל מי שתמכו, יעצו, תרמו וסייעו לעברית רב־מגדרית. </p>
        <p>זכויות יוצר׊׉: יוצרת עברית רב־מגדרית קוראת למעצב׊׍ אותיות לעצב את אותיות עברית רב־מגדרית לגופנים שלה׋, בשאיפה שעברית רב־מגדרית תהיה זמינה ונגישה בכל גופן עברי. עם זאת, שלדי האותיות החדשות וצורניות האותיות מוגנים על פי חוק זכויות יוצר׊׉. כדי לעצב ולהוסיף את אותיות עברית רב־מגדרית לגופנים נוספים, יש לקבל אישור מראש ובכתב מהיוצרת. יתכן והדבר יהיה כרוך בתשלום, ובכל מקרה אין לעשות זאת ללא אישור כזה. ליישום עברית רב־מגדרית בגופנים שלכ׋, אנא צרו קשר בטופס שנמצא <a class="internal-link" href="#contact">כאן</a>. מידע מלא על זכויות השימוש נמצא בקובץ הרישיון, שיורד יחד עם קבצי ההתקנה.</p>
        <p>הגופן "אלף" נוצר ועוצב על ידי הגילדה: מיכל סהר ודני "הטייס" מירב; מושון זר אביב, ניר ייני. הבהרה: עברית רב־מגדרית פותחה באופן עצמאי ונפרד מ-"אלף". הזכויות והקרדיט על פיתוח "אלף" שייכות למי שיצרו אותו.</p>`,
        SITE_BG_COLOR: "#fcfcfc",
    },
    CSS_CLASSES: {
        LETTER_ACTIVE_STATE: "help-letter-item--active",
    },
    MULTI_GENDER_LETTERS_MAPPING: {
        KeyB: isSafariOrFirefox ? "\u05C8" : undefined,
        KeyR: "\u05C9",
        KeyF: "\u05C9",
        KeyH: "\u05CA",
        KeyU: "\u05CA",
        KeyO: "\u05CB",
        KeyI: "\u05CB",
        KeyV: "\u05CC",
        KeyY: "\u05CD",
        KeyE: "\u05CE",
        KeyG: "\u05CF",
        KeyC: "\u05CF",
        KeyX: "\u05EB",
        KeyJ: "\u05EC",
        KeyT: "\u05ED",
        KeyZ: "\u05EE",
    },
    CHEAT_SHEET_FILE_PATH: "/assets/Multi-Gender_Hebrew_Cheat_Sheet.pdf",
    INSTALLATION_GUIDE_FILE_PATH:
        "/assets/Multi-Gender_Hebrew_Installation_Guide.pdf",
};
