const axios = require("axios");

const allowedParams = ["name", "email", "technicalFeedback", "generalFeedback"];

/**
 * @param {HTMLFormElement} formElement
 * @returns void
 */
export const sendFeedbackEmail = async (formElement) => {
    if (formElement === null) {
        return;
    }

    const formData = new FormData(formElement);
    let params = {};

    for (const entry of formData.entries()) {
        if (!allowedParams.includes(entry[0])) {
            continue;
        }

        params[entry[0]] = entry[1];
    }

    let feedbackType;
    const { generalFeedback = "", technicalFeedback = "" } = params;
    if (generalFeedback && technicalFeedback) {
        feedbackType = "all";
    } else if (generalFeedback) {
        feedbackType = "general";
    } else if (technicalFeedback) {
        feedbackType = "technical";
    }

    params.feedbackType = feedbackType;

    return await axios.post(
        "https://us-central1-multi-gender-hebrew.cloudfunctions.net/sendFeedbackEmail",
        params
    );
};
