import "./App.scss";
import { Switch, Route, useLocation } from "react-router-dom";
import { About, Main } from "./Pages";
import { useEffect } from "react";
import { sendAnalyticsEvent } from "./Helpers/Analytics";
import ReactTooltip from "react-tooltip";
import "promise-polyfill/src/polyfill";
import "core-js/features/number";
import { version } from "../package.json";

function App() {
    function handleBgColorChange(bgColor) {
        const { body } = document;
        body.style.backgroundColor = bgColor;
    }

    const location = useLocation();
    useEffect(() => {
        handleBgColorChange(null);
    }, [location]);

    const globalAnalyticsEventHandler = (e) => {
        const { target } = e;
        if (target.dataset && typeof target.dataset.track === "string") {
            sendAnalyticsEvent(target.dataset.track);
        }
    };
    useEffect(() => {
        window.addEventListener("click", globalAnalyticsEventHandler);
        return () => {
            window.removeEventListener("click", globalAnalyticsEventHandler);
        };
    });

    window.__mgh_version__ = version;

    return (
        <Switch>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/">
                <Main onBgColorChange={handleBgColorChange} />
            </Route>
            <ReactTooltip />
        </Switch>
    );
}

export default App;
