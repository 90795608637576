import React, { useState, useEffect } from "react";
import { sendAnalyticsEvent } from "../../Helpers/Analytics";
import { ReactComponent as QuestionToggle } from "../../Icons/mobile-back-button.svg";
import "./FAQ.scss";

export const MobileFAQ = (props) => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "") {
            return;
        }

        const hashValue = hash.split("#")[1];

        setOpenQuestions([hashValue]);
        const faqItemElement = document.querySelector(hash);
        if (faqItemElement !== null) {
            faqItemElement.scrollIntoView();
            sendAnalyticsEvent(`navigate-about-${hashValue}`);
        }

        return () => {};
    }, []);

    const { faqData } = props;
    const [openQuestions, setOpenQuestions] = useState([]);

    function toggleQuestionOpenState(faqItemId) {
        if (openQuestions.includes(faqItemId)) {
            setOpenQuestions(openQuestions.filter((id) => faqItemId !== id));
        } else {
            window.history.replaceState(null, null, `#${faqItemId}`);
            setOpenQuestions(openQuestions.concat([faqItemId]));
            sendAnalyticsEvent(`navigate-about-${faqItemId.id}`);
        }
    }

    return (
        <div className="faq--mobile">
            {faqData.map((faqItem) => {
                const isOpen = openQuestions.includes(faqItem.id);

                return (
                    <div key={faqItem.id} id={faqItem.id}>
                        <hr className="faq__hr" />
                        <button
                            className="button--transparent button flex question-toggle__button width-100p justify-content-space-between font-alef-multi-gndr align-items-center"
                            onClick={() => toggleQuestionOpenState(faqItem.id)}
                        >
                            <span className="faq-mobile__question">
                                {faqItem.question}
                            </span>
                            <span
                                className={`faq-mobile__question-back-icon ${
                                    isOpen
                                        ? "faq-mobile__question-back-icon--open"
                                        : ""
                                }`}
                            >
                                <QuestionToggle
                                    style={{
                                        width: "10px",
                                    }}
                                />
                            </span>
                        </button>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: faqItem.answer.replace(/\n/g, "<br />"),
                            }}
                            className={`faq-mobile__answer ${
                                isOpen
                                    ? "faq-mobile__answer--open"
                                    : "faq-mobile__answer--closed"
                            }`}
                        />
                    </div>
                );
            })}
        </div>
    );
};
