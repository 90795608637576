import { ReactComponent as DownloadIcon } from "../Icons/download.svg";

export const DownloadLink = (props) => {
    const { marginRight = 72, className = "" } = props;
    return (
        <>
            <a
                href="/assets/Multi_Gender_Hebrew.zip"
                className={`mr--${marginRight} flex header-icon ${className}`}
                download="Multi_Gender_Hebrew.zip"
                data-tip="הורדה"
            >
                <DownloadIcon className="download-icon" />
            </a>
        </>
    );
};
