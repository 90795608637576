import React from "react";
import { useIsMobile } from "../Hooks/useIsMobile";

export const Main = (props) => {
    const DesktopMain = React.lazy(() => import("./Desktop/Main"));
    const MobileMain = React.lazy(() => import("./Mobile/Main"));
    const isMobile = useIsMobile();

    return (
        <>
            <React.Suspense fallback={<></>}>
                {isMobile && <MobileMain {...props} />}
                {!isMobile && <DesktopMain {...props} />}
            </React.Suspense>
        </>
    );
};
